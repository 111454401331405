// https://www.figma.com/file/tvtkR34MAmhYEdmM6QEbtb/Shot-Page-%2B-Case-Study-%2F-HiFi-(Copy)?node-id=7633%3A141908
export const PHOTOSWIPE_ICONS = {
  closeSVG: `
    <svg class="pswp__icn" aria-hidden="true" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M11.53 1.53A.75.75 0 0 0 10.47.47L6 4.94 1.53.47A.75.75 0 1 0 .47 1.53L4.94 6 .47 10.47a.75.75 0 1 0 1.06 1.06L6 7.06l4.47 4.47a.75.75 0 1 0 1.06-1.06L7.06 6l4.47-4.47Z"/>
    </svg>
  `,
  zoomSVG: `
    <svg class="pswp__icn" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M13.854 14.9147C12.5764 15.9577 10.9446 16.5833 9.16667 16.5833C5.07056 16.5833 1.75 13.2628 1.75 9.16667C1.75 5.07056 5.07056 1.75 9.16667 1.75C13.2628 1.75 16.5833 5.07056 16.5833 9.16667C16.5833 10.9446 15.9577 12.5764 14.9147 13.854L18.0303 16.9697C18.3232 17.2626 18.3232 17.7374 18.0303 18.0303C17.7374 18.3232 17.2626 18.3232 16.9697 18.0303L13.854 14.9147ZM3.25 9.16667C3.25 5.89898 5.89898 3.25 9.16667 3.25C12.4344 3.25 15.0833 5.89898 15.0833 9.16667C15.0833 10.7631 14.4511 12.2118 13.4234 13.2761C13.396 13.2968 13.3696 13.3197 13.3447 13.3447C13.3197 13.3696 13.2968 13.396 13.2761 13.4234C12.2118 14.4511 10.7631 15.0833 9.16667 15.0833C5.89898 15.0833 3.25 12.4344 3.25 9.16667Z" />
      <path class="pswp__zoom-icn-bar-v" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M9.16666 5.91666C9.58087 5.91666 9.91666 6.25244 9.91666 6.66666V11.6667C9.91666 12.0809 9.58087 12.4167 9.16666 12.4167C8.75244 12.4167 8.41666 12.0809 8.41666 11.6667V6.66666C8.41666 6.25244 8.75244 5.91666 9.16666 5.91666Z" />
      <path class="pswp__zoom-icn-bar-h" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M5.91666 9.16666C5.91666 8.75244 6.25244 8.41666 6.66666 8.41666H11.6667C12.0809 8.41666 12.4167 8.75244 12.4167 9.16666C12.4167 9.58087 12.0809 9.91666 11.6667 9.91666H6.66666C6.25244 9.91666 5.91666 9.58087 5.91666 9.16666Z" />
    </svg>
  `,
  downloadSVG: `
    <svg class="pswp__icn" aria-hidden="true" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.625 16.625H2.375M14.25 8.70833L9.5 13.4583M9.5 13.4583L4.75 8.70833M9.5 13.4583V2.375" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
};
